.subsection--content {
  text-align: center;
  box-sizing: border-box;
  background-color: #ffffffb1;
  border-radius: 25px;
  width: 100%;
  padding: 20px;
  transition: all .5s linear;
  display: grid;
}

.subsection--content--noclick {
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #ffffffb1;
  border-radius: 25px;
  grid-template-rows: 32px 220px auto 1fr;
  width: 100%;
  padding: 20px;
  transition: all .5s linear;
  display: grid;
}

.subsection--content--noclick:hover {
  transition: all .5s linear;
  transform: scale(1.03);
}

.subsection--content a {
  cursor: pointer;
}

.subsection--content:hover {
  transition: all .5s linear;
  transform: scale(1.03);
}

.catalog__item-b {
  grid-template-columns: auto auto;
  margin-bottom: 10px;
  display: grid;
}

.subsection--img {
  border-radius: 25px;
  min-width: 320px;
  max-width: 320px;
  max-height: 201px;
  margin-left: auto;
  margin-right: auto;
}

.section--title--about {
  margin-top: 0;
  font-size: 20px;
  font-style: italic;
  font-weight: bold;
}

.carinfo__button {
  color: #1937b9;
  background-color: #fbfbfb;
  border-style: none;
  border-radius: 10px;
  min-width: 42px;
  min-height: 32px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 23px;
  font-family: open-sans-light;
  font-size: 16px;
  transition: all .5s linear;
  display: block;
}

.carinfo__button:hover {
  cursor: pointer;
  transition: all .5s linear;
  transform: scale(1.1);
}

.pano__button {
  color: #1937b9;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 200px;
  font-family: open-sans-light;
  font-size: 16px;
  transition: all .5s linear;
}

.video__button, .price__button {
  color: #1937b9;
  min-height: 8px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-family: open-sans-light;
  font-size: 18px;
  font-style: italic;
  transition: all .5s linear;
}

.video {
  margin-top: 13px;
  margin-bottom: 13px;
}

.video__button__back {
  color: #1937b9;
  min-height: 8px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-family: open-sans-light;
  font-size: 18px;
  font-style: italic;
  transition: all .5s linear;
  display: none;
}

.video__button__back:hover, .pano__button:hover, .video__button:hover, .price__button:hover {
  cursor: pointer;
  transition: all .5s linear;
  transform: scale(1.1);
}

.content-kunteynir-18-19 {
  grid-gap: 15px 15px;
  min-height: 500px;
  display: grid;
}

.subsection--content.backBtn {
  grid-template-rows: auto;
}

.subsection--content.header--brand {
  background-color: #f4f5f6;
  grid-template-rows: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 0;
}

.navigation-catalog {
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  margin-top: 25px;
  display: grid;
}

.subsection--noCars {
  text-align: center;
  background-color: #fff;
  border-radius: 25px;
  grid-template-rows: auto;
  gap: 25px;
  padding: 20px;
  animation-name: delayingActive;
  animation-duration: 2s;
  display: grid;
}

.navigation-catalog-button {
  cursor: pointer;
  color: #2f2f2f;
  background-color: #fff;
  border-style: none;
  border-radius: 25px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  font-family: open-sans-light;
  font-size: 16px;
  transition: all .5s linear;
}

.navigation-catalog-button:hover {
  color: #1937b9;
  transition: all .5s linear;
  transform: scale(1.05);
}

.navigation-catalog-active {
  color: #1937b9;
  font-weight: bold;
}

.top4 {
  padding: 5px;
}

.dotArrow {
  cursor: pointer;
  opacity: .6;
  background: none;
  border: none;
  margin: 20px;
  text-decoration: none;
  transition: all .5s linear;
}

.dotArrow:hover {
  opacity: 1;
  transition: all .5s linear;
  transform: scale(1.1);
}

.dotArrow img {
  box-sizing: border-box;
  width: 20px;
}

.backBtn {
  color: #1937b9;
  cursor: pointer;
  grid-column: 1 / 3;
  font-family: open-sans-light;
  font-size: 16px;
}

.subsection.carsCatalog {
  margin-top: 25px;
}

/*# sourceMappingURL=index.29c7a072.css.map */
